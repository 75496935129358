import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";
import Layout from "../components/layout";
import Markdown from "react-markdown";

// RCE CSS
import 'react-chat-elements/dist/main.css';
// MessageBox component
import { MessageBox } from 'react-chat-elements';

import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      publishedAt
      sentiment
      subjectivity
      percentage
      pythonDescription
      authors {
        name
        description
        picture {
          childImageSharp {
            fixed(width: 30, height: 30, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`;

// image {
//   publicURL
//   childImageSharp {
//     fixed {
//       src
//     }
//   }
// }

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const seo = {
    metaTitle: article.title,
    metaDescription: article.description,
    // shareImage: article.image,
    article: true,
  };



  let companyNames = []
  let companyIcons = []

  let companyMaps = []

  let companyLength = article.authors.length
  let i = 0
  while (i < companyLength) {
    {
      i == 0 && <>{companyNames.push(article.authors[i]["name"])
      }</>
    }
    {
      i != 0 && <>{companyNames.push(", ", article.authors[i]["name"])}</>
    }

    companyMaps.push(
      {
        name: article.authors[i]["name"],
        description: article.authors[i]["description"],
        picture: <Img
          fixed={article.authors[i]["picture"].childImageSharp.fixed}
          imgStyle={{ position: "static", borderRadius: "50%" }}
        />
      }
    );

    companyIcons.push(
      <Img
        fixed={article.authors[i]["picture"].childImageSharp.fixed}
        imgStyle={{ position: "static", borderRadius: "50%" }}
      />
    );
    i++;
  }



  let contentArray = article.content
  let contentMap = []
  let x = 0
  contentArray = contentArray.split('- ');
  contentArray.splice(0, 1);
  while (x < contentArray.length) {
    contentMap.push(
      {
        text: contentArray[x],
      })
    x++;
  }


  let pythonArray = article.pythonDescription
  let pythonMap = []
  let y = 0
  pythonArray = pythonArray.split('- ');
  pythonArray.splice(0, 1);
  while (y < pythonArray.length) {
    pythonMap.push(
      {
        text: pythonArray[y],
      })
    y++;
  }


  let progressBarColor = '#fbc630'
  let progressBarSymbol = '❔'
  {
    article.percentage <= 20 && <>{progressBarColor = '#46eaee',
      progressBarSymbol = '⛈'}</>
  }
  {
    20 < article.percentage <= 40 && <>{progressBarColor = '#37aabd',
      progressBarSymbol = '🌧'}</>
  }
  {
    40 < article.percentage <= 60 && <>{progressBarColor = '#3184ab',
      progressBarSymbol = '🌦'}</>
  }
  {
    60 < article.percentage <= 80 && <>{progressBarColor = '#3d558d',
      progressBarSymbol = '⛅️'}</>
  }
  {
    article.percentage > 80 && <>{progressBarColor = '#3b3667',
      progressBarSymbol = '☀️'}</>
  }


  const styleObj = {
    fontSize: "1.1rem",
    // color: "#4a54f1",
    // textAlign: "center",
    // paddingTop: "100px",
  }

  return (
    <Layout seo={seo}>
      <div>

        <div class="uk-container uk-container-large uk-margin-small-left uk-padding-small uk-margin-large-bottom">
          <Progress percent={article.percentage} status="success" theme={{
            success: {
              symbol: progressBarSymbol,
              color: progressBarColor
            },
          }} />
          {/* <h2 class="uk-text-center">{article.sentiment}</h2> */}
        </div>





        <div class="uk-flex uk-child-width-1-3@s uk-flex-left">
          <div class="uk-card uk-card-default uk-card-body uk-padding-remove "> <div>

            <p className="uk-margin-remove-bottom uk-background-secondary uk-light">
              <div>
                <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
                  <div className="uk-margin-small-top uk-margin-small-left uk-margin-small-right uk-text-bold">
                    <h1 id="articleTitle">
                      {article.title}
                    </h1>
                  </div>
                </div>

                <div id="time" className="uk-padding-small uk-text-meta uk-text-left uk-margin-small-bottom">
                  <Moment fromNow>{article.publishedAt}</Moment>
                </div>
              </div>
            </p>

            <div className="uk-grid-large uk-flex-left" data-uk-grid="true">
              <div>
                <div className="uk-margin-small-bottom uk-margin-small-left">
                  <button id="button" class="uk-button uk-button-link" uk-toggle="target: #toggle-animation; animation: uk-animation-fade">➤ {companyNames}</button>

                  <div id="toggle-animation" class="uk-child-width-1@s uk-margin-small-top" hidden uk-grid>
                    {companyMaps.map(companyMaps =>
                      <div>
                        <div>
                          <div className="uk-width-large uk-margin-small-right uk-margin-small uk-card-default uk-card-muted">
                            <div className="uk-padding-remove uk-card-body">

                              <div>
                                <div class="uk-card-header uk-padding-remove">

                                  <div className="uk-padding-remove uk-margin-small-top uk-grid-small uk-flex-left uk-text-bold" data-uk-grid="true">
                                    <div>
                                      <p id="articleContent" className="uk-margin-small-left">
                                        {companyMaps.picture}
                                      </p>
                                    </div>
                                    <div className="uk-width-expand">
                                      <p id="articleContent" className="uk-margin-remove-bottom">
                                        {companyMaps.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div id="companyDescription" class="uk-card-body uk-padding-small">
                                  {companyMaps.description}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div></div>
        </div>




        <p className="uk-section uk-text-large">
          <div className="uk-container uk-container-small">


            {contentMap.map(contentMap =>
              <div className="uk-margin-small-top uk-margin-medium-right">
                <MessageBox
                  position={'left'}
                  type={'text'}
                  text={<div style={styleObj}>{contentMap.text}</div>}
                  date={null}
                />
              </div>
            )}

            <hr className="uk-divider-icon uk-margin-medium-top uk-margin-medium-bottom" />

            {pythonMap.map(pythonMap =>
              <div className="uk-margin-small-top uk-margin-medium-left">
                <MessageBox
                  position={'right'}
                  type={'text'}
                  text={<div style={styleObj}>{pythonMap.text}</div>}
                  date={null}
                />
              </div>
            )}

            {/* <div className="uk-margin-medium-top uk-margin-medium-bottom uk-margin-medium-left">
              <MessageBox
                position={'right'}
                type={'text'}
                text={<div style={styleObj}>{article.pythonDescription}</div>}
                date={null}
              />
            </div> */}

            <hr className="uk-divider-small uk-margin-large-top" />

            <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
              {/* <img>
                {article.authors.picture && (
                  <Img
                    fixed={article.authors.picture.childImageSharp.fixed}
                    imgStyle={{ position: "static", borderRadius: "50%" }}
                  />
                )}

              </img> */}

              <div className="uk-width-expand">
                <p id="name" className="uk-margin-remove-bottom">
                  {companyIcons}
                </p>
                <p id="time" className="uk-text-meta uk-margin-remove-top">
                  <Moment format="MMM Do YYYY">{article.publishedAt}</Moment>
                </p>
              </div>
            </div>
          </div>
        </p>
      </div>
    </Layout >
  );
};

export default Article;
